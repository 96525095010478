(function(i, p, w, d, b, t) {

    var slider_ad = {
    
        styles: function() {
            var html = '<style>' +
                '@keyframes slider-ad-up { from { transform:translateY('+ (this.settings.image_height + 20) +'px); } to { transform:translateY(0px); }}' +
                this.id + ' { position:fixed; bottom:0; right:0; z-index:1041; transform:translateY('+ (this.settings.image_height + 20) +'px); }' +
                this.id + '.animate { animation-name:slider-ad-up; animation-duration:1s; animation-timing-function:ease-in-out; animation-iteration-count:1; animation-fill-mode:forwards; }' +
                this.id + ' .slider-ad-close { display:block; width:20px; height:20px; position:absolute; z-index:1042; top:-20px; right:0px; font-size:16px; color:#222; opacity:0.8; cursor:pointer; }' +
                this.id + ' .slider-ad-close svg { pointer-events:none; }' +
                this.id + ' .slider-ad-content img,' +
                this.id + ' .slider-ad-content a { display:block; }' +
            '</style>';
            return html;
        },
        html: function() {
            var html = this.styles();
            html += '' +
                '<div id="'+ this.id.replace('#','') +'" class="slider-ad">' +
                    '<div class="slider-ad-content">' +
                        '<a class="slider-ad-link" href="'+ this.settings.click_tag +'" target="_blank">' +
                            '<img src="'+ this.settings.image +'">' +
                        '</a>' +
                    '</div>' +
                    '<a class="slider-ad-close">' +
                        '<i class="fas tnt-times"></i>' +
                        '<span class="sr-only">Close</span>' +
                    '</a>' +
                '</div>';

            if (this.settings.tracking_pixel) {
                html += '<div style="display:none;"><img src="' + this.settings.tracking_pixel + '" height="1" width="1"></div>';
            }

            return html;
        },
        closeAd: function(e) {
            if (e.target.classList.contains('slider-ad-close')) {
                var node = d.getElementById(slider_ad.id.replace('#',''));
                node.removeEventListener('click', slider_ad.closeAd);
                node.parentNode.removeChild(node.parentNode.querySelector('style'));
                node.parentNode.removeChild(node);
                if (slider_ad.close_timer) {
                    w.clearTimeout(slider_ad.close_timer);
                }
            }
        },
        init: function() {
            p.parentNode.insertAdjacentHTML('afterend', this.html(this.settings));
            var insertedAd = p.parentNode.parentNode.querySelector('.slider-ad');
            insertedAd.classList.add('animate');
            d.getElementById(this.id.replace('#','')).addEventListener('click', this.closeAd);
            
            if (slider_ad.settings.close_after > 0) {
                slider_ad.close_timer = w.setTimeout(function(){
                    slider_ad.closeAd({target: insertedAd.querySelector('.slider-ad-close')});
                }, slider_ad.settings.close_after * 1000 + 1000); // adds an extra second for the animation
            }
        },
        scroll: function() {
            if (w.scrollY >= slider_ad.settings.scroll_pos) {
                slider_ad.init();
                d.removeEventListener('scroll', slider_ad.scroll);
            }
        },
        load: function(settings) {
            this.settings = settings;
            this.id = '#slider-ad-'+ this.settings.line_item_id +'-'+ this.settings.creative_id;
            this.settings.image_width = parseInt(this.settings.image_width);
            this.settings.image_height = parseInt(this.settings.image_height);
            this.settings.scroll_pos = parseInt(this.settings.scroll_pos);
            this.settings.close_after = parseInt(this.settings.close_after);
            
            var that = this;
            
            if (this.settings.scroll_pos > 0) {
                d.addEventListener('scroll', that.scroll);
            } else {
                this.init();
            }
        }
    };

    try {
        if (!i) throw 'Friendly iframe required.';

        t.cmds.forEach(function(item) {
            slider_ad.load(item.call());
            return false;
        });
    } catch(e) {
        if (w.console) w.console.warn(e);
    }
})(
    (window.inDapIF ? true : false),
    (window.inDapIF ? window.frameElement : null),
    (window.inDapIF ? window.parent : window),
    (window.inDapIF ? window.parent.document : document),
    (window.inDapIF ? window.parent.document.body : document.body),
    (window.__TNT_AD || {})
);